"use strict";

document.addEventListener('DOMContentLoaded', function () {
  $(document).foundation();
  $("#read-more").click(function () {
    $('html, body').animate({
      scrollTop: $("#category-description").offset().top
    }, 5);
  });
  var nav = document.getElementById('site-navigation');
  var menuBtn = document.querySelector('.menu-btn');
  var lines = document.querySelectorAll('.menu-btn .line');
  var ul = document.querySelectorAll("nav#site-navigation ul li ul");
  var li = document.querySelectorAll("nav#site-navigation ul li ul li");
  var fadeElements = document.querySelectorAll('.fade-in');
  var header = document.querySelector('header');
  var navbarHeight = header.offsetHeight;
  var didScroll = false;
  var body = jQuery("body");
  var lastScrollTop = 0;
  var delta = 5;
  menuBtn.addEventListener('click', function () {
    nav.classList.toggle('open-nav');
    lines.forEach(function (line) {
      return line.classList.toggle('line-cross');
    });
    lines[1].classList.toggle('line-fade-out');
  });
  function isScrolledIntoView(elem) {
    var docViewTop = window.scrollY;
    var docViewBottom = docViewTop + window.innerHeight + 200;
    var elemTop = elem.offsetTop;
    var elemBottom = elemTop + elem.offsetHeight;
    return elemBottom <= docViewBottom && elemTop >= docViewTop;
  }
  ul.forEach(function (ul) {
    if (ul.classList.contains('children')) {
      ul.parentNode.classList.add('hasChildren');
    }
  });
  li.forEach(function (li) {
    if (li.classList.contains('hasChildren')) {
      li.querySelector('a').insertAdjacentHTML('beforeend', "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
    }
  });
  document.addEventListener('click', function (e) {
    if (!nav.contains(e.target)) {
      nav.classList.remove('nav-open');
    }
  });
  $(document).on('change', '.variation-radios input', function () {
    $('.variation-radios input:checked').each(function (index, element) {
      var radio = $(element);
      var radioName = radio.attr('name');
      var radioValue = radio.attr('value');
      $('select[name="' + radioName + '"]').val(radioValue).trigger('change');
    });
  });
  $('.variation-radios input:checked').trigger('change');
  $('.custom-quantity-input .plus').click(function (e) {
    var $input = $(this).closest('.custom-quantity-input').find('.qty');
    var val = parseInt($input.val());
    if (!isNaN(val)) {
      $input.val(val + 1).change();
    }
  });
  $('.custom-quantity-input .minus').click(function (e) {
    var $input = $(this).closest('.custom-quantity-input').find('.qty');
    var val = parseInt($input.val());
    if (!isNaN(val) && val > 1) {
      $input.val(val - 1).change();
    }
  });

  // Function to update the #count element with the selected radio button value
  function updateCount() {
    var selectedValue = $('input[name="attribute_pa_aantal"]:checked').val();
    $('#count').text(selectedValue);
  }

  // Run the function on page load to set the initial value
  updateCount();

  // Run the function whenever a radio button is selected
  $('input[name="attribute_pa_aantal"]').change(function () {
    updateCount();
  });

  // Initialize Magnific Popup
  $('.magnific-popup').magnificPopup({
    type: 'image',
    mainClass: ' mfp-with-zoom',
    removalDelay: 300,
    gallery: {
      enabled: true
    }
  });
  var swiperProductThumbs = new Swiper(".swiper-product-thumbs", {
    spaceBetween: 10,
    slidesPerView: 5,
    freeMode: true,
    watchSlidesProgress: true
  });
  var swiperProductImage = new Swiper(".swiper-product-image", {
    spaceBetween: 10,
    autoHeight: true,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    },
    keyboard: {
      enabled: true
    },
    thumbs: {
      swiper: swiperProductThumbs
    }
  });
  var galleryThumbs = new Swiper(".gallery-thumbs", {
    centeredSlidesBounds: true,
    direction: "horizontal",
    spaceBetween: 10,
    slidesPerView: 3,
    freeMode: false,
    breakpoints: {
      480: {
        direction: "vertical",
        slidesPerView: 4
      }
    }
  });
  jQuery('.product-label-toggle').click(function ($) {
    jQuery(this).closest('.product-filter').find('.product-filter-dropdown').slideToggle();
    jQuery(this).closest('.product-filter').find('i').toggleClass('open');
  });
  var minicartheader = jQuery('.mini-cart.header');
  var minicart = jQuery(".dropdown-menu-mini-cart");
  minicartheader.click(function () {
    minicart.slideToggle();
    jQuery(".account-label i").toggleClass('open');
  });
  var swiper = new Swiper('.product-swiper-slider', {
    slidesPerView: 2,
    // Show 6 and a half slides
    spaceBetween: 10,
    // Adjust spacing between slides as needed
    pagination: {
      el: '.product-swiper-pagination',
      clickable: true
    },
    breakpoints: {
      '1200': {
        slidesPerView: 5,
        spaceBetween: 30
      }
    }
  });
  var galleryImage = new Swiper(".gallery-image", {
    direction: "horizontal",
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-product-next",
      prevEl: ".swiper-product-prev"
    },
    a11y: {
      prevSlideMessage: "Previous slide",
      nextSlideMessage: "Next slide"
    },
    keyboard: {
      enabled: true
    },
    thumbs: {
      swiper: galleryThumbs
    }
  });
  function checkScroll() {
    var scrollTop = $(window).scrollTop();
    var header = $('.site-header'); // Adjust the selector to your specific header element

    if (scrollTop > 0) {
      // If the header is not at the top, add the class
      header.addClass('scrolled');
    } else {
      // If the header is at the top, remove the class
      header.removeClass('scrolled');
    }
  }

  // Run the check on page load
  checkScroll();

  // Run the check every time the page is scrolled
  $(window).on('scroll', function () {
    checkScroll();
  });
  var top_swiper = null; // Declare swiper variable
  var swiperContainer = document.querySelector('.swiper-top-mobile');
  if (swiperContainer) {
    var initSwiperOnMobile = function initSwiperOnMobile() {
      // If the window is wider than 1023px (desktop)
      if (window.innerWidth > 1023) {
        // If Swiper is initialized, destroy it
        if (top_swiper) {
          top_swiper.destroy(true, true); // Destroy Swiper on desktop
          top_swiper = null; // Reset swiper variable
        }
        // Optionally remove swiper classes
        removeSwiperClasses(swiperContainer);
      }
      // If the window is 1023px or less (mobile) and Swiper is not initialized
      else if (window.innerWidth <= 1023 && !top_swiper) {
        // Add the necessary Swiper classes
        addSwiperClasses(swiperContainer);

        // Initialize Swiper
        top_swiper = new Swiper(swiperContainer, {
          slidesPerView: 1,
          autoplay: {
            // Autoplay settings
            delay: 3000,
            // Delay between transitions (in ms)
            disableOnInteraction: false // Continue autoplay after user interaction
          }
        });
      }
    };
    var removeSwiperClasses = function removeSwiperClasses(container) {
      // Optionally remove swiper-specific classes to reset desktop layout
      container.classList.remove('swiper-container-initialized', 'swiper-container-horizontal');
      var wrapper = container.querySelector('.swiper-wrapper');
      if (wrapper) {
        wrapper.classList.remove('swiper-wrapper');
      }
      var slides = container.querySelectorAll('.swiper-slide');
      slides.forEach(function (slide) {
        slide.classList.remove('swiper-slide');
      });
    };
    var addSwiperClasses = function addSwiperClasses(container) {
      // Add swiper container and wrapper classes for mobile
      container.classList.add('swiper-container', 'swiper-container-horizontal');
      var wrapper = container.querySelector('ul'); // Adjust for <ul> wrapper
      if (wrapper) {
        wrapper.classList.add('swiper-wrapper');
      }

      // Add swiper-slide class to each <li>
      var slides = container.querySelectorAll('li'); // Target the <li> elements
      slides.forEach(function (slide) {
        slide.classList.add('swiper-slide');
      });
    };
    // Initialize Swiper on page load
    initSwiperOnMobile();

    // Reinitialize Swiper on window resize
    window.addEventListener('resize', function () {
      initSwiperOnMobile();
    });
  }
  var aside = jQuery('#filter-sidebar');
  if (aside.length > 0) {
    jQuery("#close-sidebar-filter").click(function () {
      aside.removeClass('open');
      body.removeClass('open');
    });
    jQuery("#open-mobile-filters").click(function () {
      aside.toggleClass('open');
      body.addClass("open");
    });
    jQuery(document).mouseup(function (e) {
      if (!aside.is(e.target) // if the target of the click isn't the container...
      && aside.has(e.target).length === 0)
        // ... nor a descendant of the container
        {
          aside.removeClass('open');
          body.removeClass('open');
        }
    });
  }
});